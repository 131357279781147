<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type { CSSProperties } from "vue";
import { theme } from '#tailwind-config'
import { useChatStyles } from '~/composables/useChatStyles'
import PlantSchedule from './PlantSchedule.vue';
const uiStore = useUiStore()
const chatStore = useChatStore()
const dockStore = useDockStore()


const {activePanel, includeDataInChat} = storeToRefs(dockStore)
const { history: storeHistory, tosAccepted } = storeToRefs(chatStore)

const {setIncludeDataInChat, setActivePanel, setSwitchToPanel} = dockStore
const {panelState} = storeToRefs(dockStore)

const {setHistory, acceptTos} = chatStore


const { chatDialogOpen, chatDialogPinned, windowHeight } = storeToRefs(uiStore)
const { toggleChatDialog, togglePinChatDialog } = uiStore
const motions = useMotions()

const chatDialogRef =  ref<HTMLElement | null>(null)
const toolbarRef = ref(null)
const deepChatRef = ref<HTMLElement | null>(null)

const history = ref([])
const isLoaded = ref(false)
const route = useRoute()
const user = useSupabaseUser()
const colorMode = useColorMode()
const base_host = useRuntimeConfig().public.base_host
const introMessage = ref({ text: "Hello, how can I help you?", role: "assistant" })
const randomKey = ref(0)

const projectData = useProjectData();
const { getProjectAnalysis, getPanelProps } = projectData;

const { tools } = useTools()

// const loadPanelProps = async () => {
//   let panelProps = useNuxtData(`projects-panelprops-${owner_id}-${project_id}`)?.value;

//   if (!panelProps) {
//     panelProps = await refreshNuxtData(`projectpanels-${owner_id}-${project_id}`);
//   }

//   if (!panelProps) {
//     panelProps = await getPanelProps(project_id, owner_id, owner_type, true);
//   }

//   return { data: null, status: panelProps?.status, error: panelProps?.error }

// }

const owner_id = route.params.type == 'user' ? user.value.id : route.params.team
const project_id = route.params.project
const owner_type = route.params.type

// const { data: panelProps, error: panelPropsError, status: panelPropsStatus } = await loadPanelProps()
// if (panelProps?.data) {
  
// }

const currentTool = computed(() => {
  
  let tool = tools.find(tool => tool.uid === panelState?.value?.[activePanel?.value]?.tool_uid)?.label
  return tool
})

watch(colorMode, async (mode) => {
  randomKey.value++

})

onClickOutside(chatDialogRef, () => {
  if (!chatDialogPinned.value) {
    toggleChatDialog(false)
  }
}, { ignore: [toolbarRef] })


const include = ref(true)

watch(include, (p) => {
  setIncludeDataInChat(p)
})


const {
  chatStyle,
  inputAreaStyle,
  textInput,
  submitButtonStyles,
  auxiliaryStyle,
  avatars,
  messageStyles,
} = useChatStyles()

const loadChat = async () => {
  if (import.meta.client) {
    await import('deep-chat')
    isLoaded.value = true
  }
}


useNuxtApp().hook('app:mounted', async () => {  
  await loadChat
})

watch(history, (h) => {
  // console.log('watch: history', h)
  setHistory(h, route.params.project as string || 'default')
})


const handleNewMessage = (message) => {
  if (!message.detail.isInitial) {
    history.value.push(message.detail.message);
  }
}

function zipRowsAndColumns(data) {
  const { rows, columns } = data;
  
  return rows.map(row => {
    const zippedRow = {};
    columns.forEach((column, index) => {
      const key = column.title || column.field;
      zippedRow[key] = row[`col${index}`];
    });
    return zippedRow;
  });
}

const createChatRequest = async (body: any) => {
  let analysis = panelState.value?.[activePanel.value]?.data?.output || panelState.value?.[activePanel.value]?.output

  if (analysis) {
    if (panelState?.value?.[activePanel?.value]?.data?.tool_uid == '4447bd6c-9ade-47c3-891b-2d88576a2ec7') {
      analysis = zipRowsAndColumns({ rows: toRaw(analysis.tables).rows, columns: toRaw(analysis.tables).columns });
      console.log('zippedData', analysis)
    }
    
    const newBody = {
      ...body,
      additionalData: toRaw(analysis)
    };
    return newBody;
  }
  return body;
}


const handler = async (body, signals) => {
  const controller = new AbortController();
  const signal = controller.signal;

  try {
    if(includeDataInChat.value==true) {
      body = await createChatRequest(body);
    }
    // console.log('Request body:', body);

    const response = await fetch('/api/sse', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      signal,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    signals.onOpen();

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value, { stream: true });
      const messages = chunk.split('\n\n').filter(Boolean);

      for (const message of messages) {
        if (message.startsWith('data: ')) {
          try {
            const parsed = JSON.parse(message.slice(6));
            if (parsed.text) {
              signals.onResponse({ text: parsed.text });
            }
            if (parsed.end) {
              signals.onClose();
              return; // Exit the handler
            }
          } catch (error) {
            console.error('Error parsing message:', error);
          }
        }
      }
    }

    signals.onClose();
  } catch (e) {
    if (e.name === 'AbortError') {
      console.log('Fetch aborted');
    } else {
      console.error('Handler error:', e);
      signals.onResponse({ error: 'Error setting up SSE connection: ' + e.message });
    }
  } finally {
    signals.onClose();
  }

  signals.stopClicked.listener = () => {
    controller.abort();
  };
};


const activatePanelWithData = () => {
  // find the first panel in panelState.value that has data.tool_uid == '4447bd6c-9ade-47c3-891b-2d88576a2ec7'
  const panel = Object.values(panelState.value).find(panel => panel.data?.tool_uid === '4447bd6c-9ade-47c3-891b-2d88576a2ec7')
  
  if (panel) {
    setSwitchToPanel(panel.data.panel_id)
  }
  
}


</script>

<template>
  <transition :css="false" @leave="(el, done) => motions['chat_modal'].leave(done)">
    <KeepAlive>
      <div ref="chatDialogRef" v-motion="`chat_modal`" :initial="{ opacity: 0, x: 100 }"
        :enter="{ opacity: 1, x: 0, transition: { duration: 50 } }"
        :leave="{ opacity: 0, x: 100, transition: { duration: 50 } }" v-if="chatDialogOpen == true"
        :class="['overflow-hidden absolute right-[4vw] bottom-[18vw] sm:bottom-[10vw] w-[300px] h-[50vh] rounded-[1.65em] border-[1px] border-primary/10 transition-all duration-450 ease-out bg-background/70', { 'shadow-xxl backdrop-blur-md': !chatDialogPinned, 'shadow-lg backdrop-blur-sm border-primary/20': chatDialogPinned }, $attrs.class]">

        <!-- <GlowBox class="z-10 pointer-events-none flex flex-col absolute w-full rounded-[1.65em]"></GlowBox> -->

        <div class="group flex w-full h-full p-0">
          <div v-if="!isLoaded" class="flex h-full w-full justify-center items-center p-4">
            <Button @click="loadChat" variant="outline" class="p-2 rounded-full p-2 aspect-square">
              <Icon name="material-symbols:power-settings-new-outline-rounded" size="30" />
            </Button>
          </div>
          <ClientOnly>
            <deep-chat v-if="windowHeight && isLoaded" :onNewMessage="handleNewMessage" ref="deepChatRef" id="deepchat"
              :submitButtonStyles="submitButtonStyles" :chatStyle="chatStyle" :introMessage="introMessage"
              :avatars="avatars" :messageStyles="messageStyles" :inputAreaStyle="inputAreaStyle" :textInput="textInput"
              :auxiliaryStyle="auxiliaryStyle" :requestBodyLimits="{ maxMessages: 0, totalMessagesMaxCharLength: 0 }"
              :connect="{ url: `${base_host}/api/sse`, stream: true, additionalBodyProps: { history }, handler: handler }"
              :history="history" />

          </ClientOnly>

          <div class="flex absolute top-0 w-full justify-end p-0">
            <Button @click="togglePinChatDialog()"
              :class="['left', {
                'opacity-100 md:opacity-0':
                  !chatDialogPinned
              }, 'sm:group-hover:opacity-100  relative transition-all duration-250 ease-out', { 'rotate-45': !chatDialogPinned }]"
              variant="text">
              <Icon name="bi:pin-fill" size="18"
                :class="{ 'text-muted-foreground/30': !chatDialogPinned, 'text-primary/80': chatDialogPinned }" />
            </Button>
          </div>
          <div v-if="!tosAccepted"
            class="flex absolute pointer-events-all w-full bottom-12 w-[90%] text-center justify-center items-center p-0 rounded-full text-xs z-[1000]">
            <p class="text-xs text-muted-foreground whitespace-nowrap select-none">
              By chatting you agree to our <NuxtLink to="/legal/policy" class="underline" target="_blank">Privacy Policy
              </NuxtLink>
            </p>
            <Button @click="acceptTos" class="pointer-events-all rounded-full px-0 ml-1" variant="icon">
              <Icon name="material-symbols:close" size="15" />
            </Button>
          </div>

          <div class="hidden flex absolute bottom-2 justify-center items-center">
            <Input class="rounded-full text-xs bg-transparent border-2 border-primary/10"
              placeholder="Send a message" />
            <Button class="rounded-full px-0 mx-2" variant="text">
              <Icon name="material-symbols:send-rounded" size="20" />
            </Button>
          </div>

          <div v-if="isLoaded" class="flex pointer-events-all absolute bottom-0 w-fit justify-start p-2 z-[1001]">
            <TooltipProvider :delayDuration="0" :skipDelayDuration="700">
              <Tooltip>
                <TooltipTrigger>
                  <!-- <Button @click="" variant="icon" class="relative -top-1">
                    <Icon name="mdi:table-check" size="20" />
                  </Button> -->
                  
                  <Toggle :disabled="!currentTool" @click="activatePanelWithData"
                    :class="['group/toggle', { 'bg-gradient-to-r from-orange-500 to-purple-500': includeDataInChat }, {'!cursor-default': !currentTool}]"
                    v-model:pressed="include" aria-label="Toggle include data"
                    class="rounded-full aspect-square w-10 h-10 p-1 relative -top-1 left-1">
                    <Icon name="mdi:table-check" size="20"
                      :class="{ 'text-white': includeDataInChat, 'text-muted-foreground/50 group-hover/toggle:text-primary': !includeDataInChat }" />
                  </Toggle>

                </TooltipTrigger>
                <TooltipContent v-if="currentTool"
                  class="pointer-events-all bg-primary text-background shadow-xxl !animate-none select-none border-none">
                  <p v-if="currentTool">Include this {{ currentTool?.toLocaleLowerCase() }} in the chat</p>
                  <p v-else>Apply an analysis toolbar tool to chat with the results.</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>





        </div>
      </div>
    </KeepAlive>
  </transition>


  <transition :css="false" @leave="(el, done) => motions['buttonAnim'].leave(done)">
    <Button v-motion="`buttonAnim`" :initial="{ opacity: 0, x: -100 }"
      :enter="{ opacity: 1, x: 0, transition: { duration: 250, ease: 'cubicBezier(0.25, 0.1, 0.25, 1.0)' } }"
      :leave="{ opacity: 0, x: -100, transition: { duration: 50 } }" v-if="chatDialogOpen == false"
      @click="toggleChatDialog(true)" variant="ghost" class="absolute right-[4vw] bottom-[18vw] sm:bottom-[4vw] w-12 h-12 
    p-3 bg-primary/90 rounded-full shadow-xxl hover:bg-primary/75">

      <Icon name="solar:chat-line-linear" class="text-background" size="20" />
    </Button>
  </transition>
</template>

<style>

#deepchat {
  height: calc(50vh - 30px) !important;
}



</style>  
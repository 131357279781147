const _sm = "0 1px 2px 0 rgb(0 0 0 / 0.05)"
const _DEFAULT = "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
const _md = "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
const _lg = "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
const _xl = "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)"
const _2xl = "0 25px 50px -12px rgb(0 0 0 / 0.25)"
const _inner = "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)"
const _none = "none"
const _xxl = "0px 5.8px 1.4px -13px rgba(0, 0, 0, 0.058), 0px 10.2px 3.3px -13px rgba(0, 0, 0, 0.078), 0px 13.7px 6.3px -13px rgba(0, 0, 0, 0.09), 0px 17.1px 11.2px -13px rgba(0, 0, 0, 0.1), 0px 22.2px 20.9px -13px rgba(0, 0, 0, 0.113), 0px 39px 50px -13px rgba(0, 0, 0, 0.15)"
const _menu = "0px 2.2px 1px -78px rgba(0, 0, 0, 0.048), 0px 5.4px 2.3px -78px rgba(0, 0, 0, 0.069), 0px 10.1px 4.4px -78px rgba(0, 0, 0, 0.085),0px 18.1px 7.8px -78px rgba(0, 0, 0, 0.101), 0px 33.8px 14.6px -78px rgba(0, 0, 0, 0.122), 0px 81px 35px -78px rgba(0, 0, 0, 0.17)"
const config = { "sm": _sm, "DEFAULT": _DEFAULT, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "inner": _inner, "none": _none, "xxl": _xxl, "menu": _menu,  }
export { config as default, _sm, _DEFAULT, _md, _lg, _xl, _2xl, _inner, _none, _xxl, _menu }
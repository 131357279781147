const _0 = "0"
const _5 = "0.05"
const _10 = "0.1"
const _15 = "0.15"
const _20 = "0.2"
const _25 = "0.25"
const _30 = "0.3"
const _35 = "0.35"
const _40 = "0.4"
const _45 = "0.45"
const _50 = "0.5"
const _55 = "0.55"
const _60 = "0.6"
const _65 = "0.65"
const _70 = "0.7"
const _75 = "0.75"
const _80 = "0.8"
const _85 = "0.85"
const _90 = "0.9"
const _95 = "0.95"
const _100 = "1"
const _DEFAULT = 0
const config = { "0": _0, "5": _5, "10": _10, "15": _15, "20": _20, "25": _25, "30": _30, "35": _35, "40": _40, "45": _45, "50": _50, "55": _55, "60": _60, "65": _65, "70": _70, "75": _75, "80": _80, "85": _85, "90": _90, "95": _95, "100": _100, "DEFAULT": _DEFAULT,  }
export { config as default, _0, _5, _10, _15, _20, _25, _30, _35, _40, _45, _50, _55, _60, _65, _70, _75, _80, _85, _90, _95, _100, _DEFAULT }
const _0 = "0"
const _50 = ".5"
const _75 = ".75"
const _90 = ".9"
const _95 = ".95"
const _100 = "1"
const _105 = "1.05"
const _110 = "1.1"
const _125 = "1.25"
const _150 = "1.5"
const _DEFAULT = 0
const config = { "0": _0, "50": _50, "75": _75, "90": _90, "95": _95, "100": _100, "105": _105, "110": _110, "125": _125, "150": _150, "DEFAULT": _DEFAULT,  }
export { config as default, _0, _50, _75, _90, _95, _100, _105, _110, _125, _150, _DEFAULT }